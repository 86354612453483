import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Stack from "../components/stack"
import BloqueContacto from "../components/bloque-contacto"
import Planes from "../components/planes"
import imgWebsArtesanales from '../images/dibus/webs-artesanales.svg'
import imgSabemosHacemos from '../images/dibus/sabemos-hacemos.svg'
import imgEstamosContigo from '../images/dibus/estamos-contigo.svg'
import imgOrientacionUx from '../images/dibus/orientacion-ux.svg'
import imgPintando from '../images/dibus/pintando.svg'
import imgMultidisciplinar from '../images/dibus/multidisciplinar.svg'
import imgCohete from '../images/dibus/cohete.svg'


const IndexPage = () => (
	<Layout className="page-home">
		<SEO title="Inicio" />

		<section className="bloque bloque-featured">
			<div className="container">
				<h1>Somos cocowat y hacemos webs</h1>
				<h2>Nos rompemos el coco para hacerte una web alucinante</h2>
			</div>
		</section>

		<section className="container bloque-top bloque-why">
			<div className="box">
				<h3 className="title">Por qué deberías elegirnos</h3>
				<p className="intro">Te damos unas cuantas buenas razones de por que confiar en nosotros...</p>

				<div className="row">
					<div className="col-sm-6 col-md-3">
						<figure>
							<img className="why-img" src={imgWebsArtesanales} title="Webs artesanales" alt="" />
						</figure>
						<h4>Webs artesanales</h4>
						<p>Hacemos el producto a medida sin plantillas</p>
					</div>
					<div className="col-sm-6 col-md-3">
						<figure>
							<img className="why-img" src={imgSabemosHacemos} alt="" title="Sabemos lo que hacemos" />
						</figure>
						<h4>Sabemos lo que hacemos</h4>
						<p>Más de 15 años de experiencia a nuestras espaldas</p>
					</div>
					<div className="col-sm-6 col-md-3">
						<figure>
							<img className="why-img" src={imgEstamosContigo} alt="" title="Estamos contigo" />
						</figure>
						<h4>Estamos contigo</h4>
						<p>Asesoramiento y atención antes, durante y después</p>
					</div>
					<div className="col-sm-6 col-md-3">
						<figure>
							<img className="why-img" src={imgOrientacionUx} alt="" title="Orientación al usuario" />
						</figure>
						<h4>Orientación al usuario</h4>
						<p>Webs usables, accesibles y centradas en el usuario final</p>
					</div>
				</div>

				<Link to="/quienes-somos" className="btn btn-accent">Saber más</Link>
			</div>
		</section>


		<section className="bloque bloque-ofrecemos">
			<div className="container">
				<h3 className="title">Qué ofrecemos</h3>
				<p className="intro">Te ofrecemos un sin fin de productos para que tu web quede de lo más resultona y diferente al resto. </p>

				<div className="row">
					<div className="col-sm-6 bloque-color">
						<figure>
							<img src={imgPintando} alt="" title="Hacemos todo tipo de webs y aplicaciones móviles" />
						</figure>
					</div>
					<div className="col-sm-6 bloque-texto">
						<h4>Hacemos todo tipo de webs y aplicaciones móviles</h4>
						<p>Desde landing page hasta complejas aplicaciones webs a medida.</p>
					</div>
				</div>
				<div className="row">
					<div className="col-sm-6 order-sm-last bloque-color">
						<figure>
							<img src={imgMultidisciplinar} alt="" title="Equipo multidisciplinar" />
						</figure>
					</div>
					<div className="col-sm-6 order-sm-first bloque-texto">
						<h4>Equipo multidisciplinar</h4>
						<p>Para complementar el producto también hacemos:</p>
					</div>
				</div>
				<div className="row">
					<div className="col-sm-6 bloque-color">
						<figure>
							<img src={imgCohete} alt="" title="Webs diferentes" />
						</figure>
					</div>
					<div className="col-sm-6 bloque-texto">
						<h4>Webs diferentes</h4>
						<p>Nuestras webs son originales y cargan muy rápido para que el usuario se quede navegando en ella sin desesperarse.</p>
					</div>
				</div>
			</div>

		</section>

		<section className="bloque bloque-planes bg-dark-blue">

			<div className="container">
				<h3 className="title title-boxed">Nuestros super planes web</h3>
				<p className="intro">Te ofrecemos un sin fin de productos para que tu web quede de lo más resultona y diferente al resto. </p>

				<Planes />

				<Link to="/planes" className="btn btn-accent">Saber más</Link>
			</div>
		</section>

		<section className="bloque bloque-stack">
			<div className="container">
				<h3 className="title">Tecnologías que utilizamos</h3>
				<p className="intro">Nuestro equipo está formado por desarrolladores de software, diseñadores UX/UI, diseñadores gráficos, ilustradores que dominan las principales tecnologías de diseño, web, nativas, híbridas y cloud actuales.</p>
				<Stack />
			</div>
		</section>

		<section className="container bloque bloque-how">
			<h3 className="title">Cómo trabajamos</h3>
			<p className="intro">Para solucionar las necesidades de cada empresa utilizamos las metodologías y tecnologías que mejor se adaptan</p>

			<div className="row">
				<article className="col-sm-6 col-md-3">
					<div className="how-header">
						<span className="how-step">
							01
						</span>
						<h4>Te pones en contacto con nosotros</h4>
					</div>
					<div className="how-content">
						<p>Nos presentamos y nos explicas un poco que tienes en mente.</p>
					</div>
				</article>
				<article className="col-sm-6 col-md-3">
					<div className="how-header">
						<span className="how-step">
							02
						</span>
						<h4>Reunión <span>(presencial o Teams/Hangouts)</span></h4>
					</div>
					<div className="how-content">
						<p>Quedamos un día para hablar más en detalle del producto que necesitas. </p>
					</div>
				</article>
				<article className="col-sm-6 col-md-3">
					<div className="how-header">
						<span className="how-step">
							03
						</span>
						<h4>Propuesta</h4>
					</div>
					<div className="how-content">
						<p>Te hacemos nuestra propuesta. ¡Seguro que te gusta! :)</p>
					</div>
				</article>
				<article className="col-sm-6 col-md-3">
					<div className="how-header">
						<span className="how-step">
							04
						</span>
						<h4>¡Nos ponemos manos a la web!</h4>
					</div>
					<div className="how-content">
						<p>Nos rompemos el coco para hacerte una web alucinante. </p>
					</div>
				</article>
			</div>
		</section>

		<BloqueContacto />
	</Layout>
)

export default IndexPage