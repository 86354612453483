import React from "react"
import imgAdobeAfterEffects from "../images/stack-icons/adobe-after-effects.svg"
import imgAdobeCC from "../images/stack-icons/adobe-cc.svg"
import imgAdobeIllustrator from "../images/stack-icons/adobe-illustrator.svg"
import imgAdobeLightroom from "../images/stack-icons/adobe-lightroom.svg"
import imgAdobePhotoshop from "../images/stack-icons/adobe-photoshop.svg"
import imgAdobePremiere from "../images/stack-icons/adobe-premiere.svg"
import imgAndroid from "../images/stack-icons/apps-android.svg"
import imgApple from "../images/stack-icons/apps-apple.svg"
import imgIonic from "../images/stack-icons/apps-ionic.svg"
import imgSwift from "../images/stack-icons/apps-swift.svg"
import imgXamarin from "../images/stack-icons/apps-xamarin.svg"
import imgFirebase from "../images/stack-icons/back-firebase.svg"
import imgJava from "../images/stack-icons/back-java.svg"
import imgPhp from "../images/stack-icons/back-php.svg"
import imgDjango from "../images/stack-icons/back-py-django.svg"
import imgPython from "../images/stack-icons/back-python.svg"
import imgWordpress from "../images/stack-icons/back-wordpress.svg"
import imgSass from "../images/stack-icons/css-sass.svg"
import imgStylus from "../images/stack-icons/css-stylus.svg"
import imgCss from "../images/stack-icons/css.svg"
import imgMysql from "../images/stack-icons/db-mysql.svg"
import imgSqlServer from "../images/stack-icons/db-sql-server.svg"
import imgHtml from "../images/stack-icons/html.svg"
import imgJavascript from "../images/stack-icons/javascript.svg"
import imgAngular from "../images/stack-icons/js-angular.svg"
import imgNode from "../images/stack-icons/js-nodejs.svg"
import imgReact from "../images/stack-icons/js-react.svg"
import imgGatsby from "../images/stack-icons/js-gatsby.svg"
import imgVue from "../images/stack-icons/js-vue.svg"
import imgMsNet from "../images/stack-icons/ms-net.svg"
import imgMsOffice from "../images/stack-icons/ms-office.svg"
import imgBitbucket from "../images/stack-icons/bitbucket.svg"
import imgAxure from "../images/stack-icons/ui-axure.svg"
import imgInvision from "../images/stack-icons/ui-invision.svg"
import imgSketch from "../images/stack-icons/ui-sketch.svg"
import imgZeplin from "../images/stack-icons/ui-zeplin.svg"

const Stack = props => {

  return (
    <>
		<div className="row">
			<div className="col-6 col-sm-4 col-md-3 col-lg-2 col-xl-1">
				<img className="stack-icon" src={imgAdobeCC} alt="Logo Adobe CC" title="Adobe CC" />
			</div>
			<div className="col-6 col-sm-4 col-md-3 col-lg-2 col-xl-1">
				<img className="stack-icon" src={imgAdobeAfterEffects} alt="Logo Adobe After Effects" title="Adobe After Effects"/>
			</div>			
			<div className="col-6 col-sm-4 col-md-3 col-lg-2 col-xl-1">
				<img className="stack-icon" src={imgAdobeIllustrator} alt="Logo Adobe Illustrator"  title="Adobe Illustrator"  />
			</div>
			<div className="col-6 col-sm-4 col-md-3 col-lg-2 col-xl-1">
				<img className="stack-icon" src={imgAdobeLightroom} alt="Logo Adobe Lightroom" title="Adobe Lightroom" />
			</div>			
			<div className="col-6 col-sm-4 col-md-3 col-lg-2 col-xl-1">
				<img className="stack-icon" src={imgAdobePhotoshop} alt="Logo Adobe Photoshop"  title="Adobe Photoshop" />
			</div>
			<div className="col-6 col-sm-4 col-md-3 col-lg-2 col-xl-1">
				<img className="stack-icon" src={imgAdobePremiere} alt="Logo Adobe Premiere" title="Adobe Premiere"/>
			</div>
			<div className="col-6 col-sm-4 col-md-3 col-lg-2 col-xl-1">
				<img className="stack-icon" src={imgAxure} alt="Logo Axure" title="Axure" />
			</div>	
			<div className="col-6 col-sm-4 col-md-3 col-lg-2 col-xl-1">
				<img className="stack-icon" src={imgInvision} alt="Logo Invision"   title="Invision"/>
			</div>	
			<div className="col-6 col-sm-4 col-md-3 col-lg-2 col-xl-1">
				<img className="stack-icon" src={imgSketch} alt="Logo Sketch" title="Sketch" />
			</div>	
			<div className="col-6 col-sm-4 col-md-3 col-lg-2 col-xl-1">
				<img className="stack-icon" src={imgZeplin} alt="Logo Zeplin" title="Zeplin" />
			</div>	
			<div className="col-6 col-sm-4 col-md-3 col-lg-2 col-xl-1">
				<img className="stack-icon" src={imgMsOffice} alt="Logo Microsoft Office"  title="Microsoft Office"/>
			</div>	
			<div className="col-6 col-sm-4 col-md-3 col-lg-2 col-xl-1">
				<img className="stack-icon" src={imgBitbucket} alt="Logo Bitbucket" title="Bitbucket" />
			</div>								
			<div className="col-6 col-sm-4 col-md-3 col-lg-2 col-xl-1">
				<img className="stack-icon" src={imgHtml} alt="Logo HTML"  title="HTML"/>
			</div>	
			<div className="col-6 col-sm-4 col-md-3 col-lg-2 col-xl-1">
				<img className="stack-icon" src={imgCss} alt="Logo CSS" title="CSS" />
			</div>					
			<div className="col-6 col-sm-4 col-md-3 col-lg-2 col-xl-1">
				<img className="stack-icon" src={imgSass} alt="Logo Sass" title="Sass" />
			</div>
			<div className="col-6 col-sm-4 col-md-3 col-lg-2 col-xl-1">
				<img className="stack-icon" src={imgStylus} alt="Logo Stylus" title="Stylus"/>
			</div>
			<div className="col-6 col-sm-4 col-md-3 col-lg-2 col-xl-1">
				<img className="stack-icon" src={imgMysql} alt="Logo MySQL"   title="MySQL"/>
			</div>
			<div className="col-6 col-sm-4 col-md-3 col-lg-2 col-xl-1">
				<img className="stack-icon" src={imgSqlServer} alt="Logo SQL Server" title="SQL Server"  />
			</div>
			<div className="col-6 col-sm-4 col-md-3 col-lg-2 col-xl-1">
				<img className="stack-icon" src={imgJavascript} alt="Logo JavaScript" title="JavaScript" />
			</div>
			<div className="col-6 col-sm-4 col-md-3 col-lg-2 col-xl-1">
				<img className="stack-icon" src={imgAngular} alt="Logo Angular" title="Angular"/>
			</div>
			<div className="col-6 col-sm-4 col-md-3 col-lg-2 col-xl-1">
				<img className="stack-icon" src={imgNode} alt="Logo Node" title="Node" />
			</div>
			<div className="col-6 col-sm-4 col-md-3 col-lg-2 col-xl-1">
				<img className="stack-icon" src={imgReact} alt="Logo React" title="React" />
			</div>	
			<div className="col-6 col-sm-4 col-md-3 col-lg-2 col-xl-1">
				<img className="stack-icon" src={imgGatsby} alt="Logo Gatsby"  title="Gatsby"/>
			</div>				
			<div className="col-6 col-sm-4 col-md-3 col-lg-2 col-xl-1">
				<img className="stack-icon" src={imgVue} alt="Logo Vue" title="Vue"  />
			</div>	
			<div className="col-6 col-sm-4 col-md-3 col-lg-2 col-xl-1">
				<img className="stack-icon" src={imgFirebase} alt="Logo Firebase" title="Firebase" />
			</div>			
			<div className="col-6 col-sm-4 col-md-3 col-lg-2 col-xl-1">
				<img className="stack-icon" src={imgMsNet} alt="Logo .NET"  title=".NET"/>
			</div>	
			<div className="col-6 col-sm-4 col-md-3 col-lg-2 col-xl-1">
				<img className="stack-icon" src={imgJava} alt="Logo Java" title="Java" />
			</div>				
			<div className="col-6 col-sm-4 col-md-3 col-lg-2 col-xl-1">
				<img className="stack-icon" src={imgPhp} alt="Logo PHP" title="PHP" />
			</div>	
			<div className="col-6 col-sm-4 col-md-3 col-lg-2 col-xl-1">
				<img className="stack-icon" src={imgDjango} alt="Logo Django" title="Django" />
			</div>	
			<div className="col-6 col-sm-4 col-md-3 col-lg-2 col-xl-1">
				<img className="stack-icon" src={imgPython} alt="Logo Python" title="Python" />
			</div>	
			<div className="col-6 col-sm-4 col-md-3 col-lg-2 col-xl-1">
				<img className="stack-icon" src={imgWordpress} alt="Logo WordPress" title="WordPress" />
			</div>		
			<div className="col-6 col-sm-4 col-md-3 col-lg-2 col-xl-1">
				<img className="stack-icon" src={imgAndroid} alt="Logo Android" title="Android" />
			</div>	
			<div className="col-6 col-sm-4 col-md-3 col-lg-2 col-xl-1">
				<img className="stack-icon" src={imgApple} alt="Logo Apple" title="Apple" />
			</div>	
			<div className="col-6 col-sm-4 col-md-3 col-lg-2 col-xl-1">
				<img className="stack-icon" src={imgIonic} alt="Logo Ionic" title="Ionic" />
			</div>	
			<div className="col-6 col-sm-4 col-md-3 col-lg-2 col-xl-1">
				<img className="stack-icon" src={imgSwift} alt="Logo Swift" title="Swift" />
			</div>	
			<div className="col-6 col-sm-4 col-md-3 col-lg-2 col-xl-1">
				<img className="stack-icon" src={imgXamarin} alt="Logo Xamarin" title="Xamarin" />
			</div>																																																																																											
		</div>
    </>
  )
}


export default Stack